var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex"},[_c('b-input',{ref:"txtBuscaComanda",attrs:{"placeholder":"localizar","autocomplete":"off","autofocus":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.clickSel.apply(null, arguments)}},model:{value:(_vm.filtro),callback:function ($$v) {_vm.filtro=$$v},expression:"filtro"}}),(_vm.showOpenComanda)?_c('b-btn',{staticClass:"w-25 ml-4",attrs:{"variant":"success"},on:{"click":function($event){return _vm.$emit('openComanda')}}},[_vm._v("Abrir Comanda")]):_vm._e()],1),_c('hr'),_c('div',{class:{ row: _vm.isOpen }},_vm._l((_vm.comandas),function(cm){return _c('div',{key:`comanda_${cm.comanda.nome}_${Math.random()}`,class:{ 'col-12 col-md-4 col-lg-3 p-2 ': _vm.isOpen },on:{"click":function($event){return _vm.select(cm)}}},[_c('div',{staticClass:"comanda",class:{
          cmdOpen: _vm.isOpen,
          'bg-danger text-white': cm.comanda.dt_fechada,
        }},[_c('div',{staticClass:"row text-left"},[_c('div',{class:{ 'col-12 col-md-4': !_vm.isOpen, 'col-12': _vm.isOpen }},[_c('strong',[_c('i',{staticClass:"fa fa-barcode mr-1"}),_vm._v(_vm._s(_vm.getNome(cm.comanda, 0)))])]),(
              _vm.getNome(cm.comanda, 1) && _vm.getNome(cm.comanda, 1).trim() != ``
            )?_c('div',{class:{ 'col-12 col-md-4': !_vm.isOpen, 'col-12': _vm.isOpen }},[_c('i',{staticClass:"fa fa-user mr-1"}),_vm._v(_vm._s(_vm.getNome(cm.comanda, 1))+" ")]):_vm._e(),(
              _vm.getNome(cm.comanda, 2) && _vm.getNome(cm.comanda, 2).trim() != ``
            )?_c('div',{class:{ 'col-12 col-md-4': !_vm.isOpen, 'col-12': _vm.isOpen }},[_c('i',{staticClass:"fa fa-phone mr-1"}),_vm._v(_vm._s(_vm.getNome(cm.comanda, 2))+" ")]):_vm._e(),_c('div',{class:{ 'col-12 col-md-4': !_vm.isOpen, 'col-12': _vm.isOpen }},[_c('i',{staticClass:"fa fa-calendar mr-1"}),_vm._v(_vm._s(_vm._f("moment")(cm.mesa.dt_aberta,"DD/MM/YYYY HH:mm"))+" ")])]),(cm.comanda.dt_fechada)?_c('div',[_c('i',{staticClass:"fa fa-lock mr-1"}),_vm._v("Comanda Fechada ")]):_vm._e()])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }