<template>
  <div>
    <div class="d-flex">
      <b-input
        placeholder="localizar"
        v-model="filtro"
        autocomplete="off"
        ref="txtBuscaComanda"
        autofocus
        @keydown.enter="clickSel"
      />
      <b-btn
        variant="success"
        class="w-25 ml-4"
        @click="$emit('openComanda')"
        v-if="showOpenComanda"
        >Abrir Comanda</b-btn
      >
    </div>
    <hr />
    <div :class="{ row: isOpen }">
      <div
        v-for="cm in comandas"
        :key="`comanda_${cm.comanda.nome}_${Math.random()}`"
        :class="{ 'col-12 col-md-4 col-lg-3 p-2 ': isOpen }"
        @click="select(cm)"
      >
        <div
          class="comanda"
          :class="{
            cmdOpen: isOpen,
            'bg-danger text-white': cm.comanda.dt_fechada,
          }"
        >
          <div class="row text-left">
            <div :class="{ 'col-12 col-md-4': !isOpen, 'col-12': isOpen }">
              <strong
                ><i class="fa fa-barcode mr-1"></i
                >{{ getNome(cm.comanda, 0) }}</strong
              >
            </div>
            <div
              v-if="
                getNome(cm.comanda, 1) && getNome(cm.comanda, 1).trim() != ``
              "
              :class="{ 'col-12 col-md-4': !isOpen, 'col-12': isOpen }"
            >
              <i class="fa fa-user mr-1"></i>{{ getNome(cm.comanda, 1) }}
            </div>
            <div
              :class="{ 'col-12 col-md-4': !isOpen, 'col-12': isOpen }"
              v-if="
                getNome(cm.comanda, 2) && getNome(cm.comanda, 2).trim() != ``
              "
            >
              <i class="fa fa-phone mr-1"></i>{{ getNome(cm.comanda, 2) }}
            </div>
            <div :class="{ 'col-12 col-md-4': !isOpen, 'col-12': isOpen }">
              <i class="fa fa-calendar mr-1"></i>{{ cm.mesa.dt_aberta | moment("DD/MM/YYYY HH:mm") }}
            </div>
          </div>
          <div v-if="cm.comanda.dt_fechada">
            <i class="fa fa-lock mr-1"></i>Comanda Fechada
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    mesas: Array,
    isOpen: Boolean,
    showOpenComanda: Boolean,
  },
  mounted() {
    this.$refs.txtBuscaComanda.focus();
  },
  data() {
    return {
      filtro: "",
    };
  },
  watch: {},
  computed: {
    comandas: function () {
      let res = [];
      for (let m of this.mesas.filter((x) => x.id)) {
        res.push(
          ...m.comandas.map((c) => ({
            mesa: m,
            comanda: c,
          }))
        );
      }
      res = res.filter(
        (x) =>
          (this.filtro &&
            this.filtro != "" &&
            x.comanda.nome
              .toLowerCase()
              .indexOf(this.filtro.toLocaleLowerCase()) >= 0) ||
          !this.filtro
      );

      res = res.reduce((ret, vl) => {
        let has = ret.find((x) => x.comanda.nome == vl.comanda.nome);
        if (!has) {
          ret.push(vl);
        }
        return ret;
      }, []);
      console.log("filtrando", this.filtro);
      return res.sort((a, b) => {
        return parseInt(a.comanda.nome.replace("Comanda nº ", "")) >
          parseInt(b.comanda.nome.replace("Comanda nº ", ""))
          ? 1
          : -1;
      });
    },
  },
  methods: {
    getNome(comanda, index) {
      if (comanda.nome.includes("|")) {
        return comanda.nome.split("|")[index];
      } else {
        return comanda.nome;
      }
    },
    clickSel() {
      if (this.comandas && this.comandas.length > 0) {
        this.select(this.comandas[0]);
      }
    },
    select(comanda) {
      // this.$emit("mesa", comanda.mesa);
      this.$emit("comanda", comanda.comanda);
    },
  },
};
</script>

<style lang="scss" scoped>
.comanda {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    border-color: #ccc;
    box-shadow: 0px 0px 10px rgba(160, 160, 160, 0.3);
    background-color: #f5f5f5;
  }
}
.cmdOpen {
  min-height: 90px;
  background-color: #82d42c;
  &:hover {
    transform: scale(1.05);
  }
}
</style>
